import React, { useState, useEffect } from 'react';
import authService from '../api-authorization/AuthorizeService'

function DropdownComponent({ onOptionChange, endpoint, text }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const token = await authService.getAccessToken();

        const response = await fetch(endpoint, {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch options');
        }

        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  },[endpoint]);

  const handleChange = (event) => {
    onOptionChange(event.target.value);
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <select value={selectedOption} onChange={handleChange}>
        <option value="">{ text }</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>{option.name}</option>
        ))}
      </select>
    </div>
  );
}

export default DropdownComponent;
