import React, { useState } from 'react';
import authService from '../api-authorization/AuthorizeService'

function FileUploadComponent({ onBeforeUpload, onAfterUpload, endpoint }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpdateAwardBasis = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/updateawardbasis', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      console.log('Uploading file:', selectedFile);
      setMessage('Uploading file');
      const formData = new FormData();
      formData.append('file', selectedFile);
      if (onBeforeUpload != null)
        onBeforeUpload(formData);

      const token = await authService.getAccessToken();

      fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            return response.json().then(errorData => {
              throw new Error(JSON.stringify(errorData.errors));
            });
          } else {
            throw new Error('Failed to upload file: ' + response.statusText);
          }
        }
        return response.json();
      })
      .then(data => {
        console.log('Upload successful:', data);
        setMessage("File uploaded successfully.");

        if (onAfterUpload != null)
          onAfterUpload(data);
        // Reset the selected file after successful upload if needed
        //setSelectedFile(null);
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        setMessage('Error uploading file: ' + error.message);
      });
    } else {
      console.log('No file selected');
      setMessage('No file selected');
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} /><br />
      <br />
      <button onClick={handleUpload}>Upload</button>
      {message && <div>{message}</div>}
      <br></br>
      <br></br>
      <br></br>
      <button onClick={handleUpdateAwardBasis}>Update Award Basis</button>
    </div>
  );
}

export default FileUploadComponent;
