import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';

function FinancesSummary() {
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    getPrices();
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getstaking', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPrices = async () => {
    try {
      const token = await authService.getAccessToken();
      var response = await fetch('api/finances/getprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      var data = await response.json();
      setPrices(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePricesClick = async () => {
    console.log("handleUpdatePricesClick");
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/updateassetprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });

      await getPrices();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleOpen = (id) => {
    setOpenItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const buttonStyle = {
    padding: "2px",
    fontSize: "20px",
    marginLeft: "10px",
    border: 'none',
    backgroundColor: 'white',
  };

  return (
    <div>
      <h2>Staking <button style={buttonStyle} onClick={handleUpdatePricesClick}>(&#10227;)</button></h2>
      <br></br>
      {data.map(yearGroup => {
        const originalTotal = Math.abs(yearGroup.accounts
          .map(account => account.symbolGroups)
          .flat()
          .flatMap(symbolGroup => symbolGroup.transactions)
          .reduce((acc, transaction) => acc + transaction.originalBasis, 0));
        const currentTotal = Math.abs(yearGroup.accounts
          .map(account => account.symbolGroups)
          .flat()
          .flatMap(symbolGroup => symbolGroup.transactions)
          .reduce((acc, transaction) => acc + prices[transaction.symbol]?.price * transaction.originalQuantity, 0));
        return(
          <div>
            <h3>{yearGroup.year}</h3>
            <h5>{originalTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} +
              {(currentTotal - originalTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} =
              {currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h5>
            <br></br>
            {
              yearGroup.accounts.map(account => {
                const originalTotal = Math.abs(account.symbolGroups
                  .flatMap(symbolGroup => symbolGroup.transactions)
                  .reduce((acc, transaction) => acc + transaction.originalBasis, 0));
                const currentTotal = Math.abs(account.symbolGroups
                  .flatMap(symbolGroup => symbolGroup.transactions)
                  .reduce((acc, transaction) => acc + transaction.originalQuantity * prices[transaction.symbol]?.price, 0));
                return (
                  <div>
                    <h4>{account.friendlyName}</h4>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Price At Time</th>
                          <th>Current Price</th>
                          <th>Original Value</th>
                          <th>Current Value</th>
                          <th>Gain</th>
                        </tr>
                      </thead>
                      <tbody>
                      {account.symbolGroups.map((symbolGroup, index) => {
                        return (
                          <>
                            <tr style={{ borderBottom: '0px solid #fff' }}>
                              <td>
                                <button onClick={() => toggleOpen(account.id * 100 + symbolGroup.symbol + index)} className="btn" style={{ marginBottom: '0rem', padding: '0px', margin: '0px', verticalAlign: 'center' }}>
                                  {symbolGroup.symbol}
                                </button></td>
                              <td>{symbolGroup.quantity.toFixed(8)}</td>
                              <td>{Math.abs(symbolGroup.basis / symbolGroup.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                              <td>{prices[symbolGroup.symbol]?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                              <td>{Math.abs(symbolGroup.basis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                              <td>{(prices[symbolGroup.symbol]?.price * symbolGroup.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                              <td>{(prices[symbolGroup.symbol]?.price * symbolGroup.quantity + symbolGroup.basis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            </tr>
                            <tr>
                              <td colSpan="7">
                                <Collapse isOpen={openItems[account.id * 100 + symbolGroup.symbol + index]}>
                                  <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>Date</th>
                                        <th>Quantity</th>
                                        <th>Price At Time</th>
                                        <th>Current Price</th>
                                        <th>Original Value</th>
                                        <th>Current Value</th>
                                        <th>Gain</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {symbolGroup.transactions.map((transaction, index) => (
                                        <tr>
                                          <td>{new Date(transaction.date).toLocaleDateString('en-US', { dateStyle: 'short' })}</td>
                                          <td>{transaction.originalQuantity.toFixed(8)}</td>
                                          <td>{Math.abs(transaction.originalBasis / transaction.originalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                          <td>{prices[transaction.symbol]?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                          <td>{Math.abs(transaction.originalBasis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                          <td>{(prices[transaction.symbol]?.price * transaction.originalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                          <td>{(prices[transaction.symbol]?.price * transaction.originalQuantity + transaction.originalBasis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </Collapse>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      </tbody>
                      <tfoot>
                        <tr style={{ borderBottom: '0px solid #fff' }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{originalTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                          <td>{currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                          <td>{(currentTotal - originalTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        </tr>
                      </tfoot>
                    </table>
                  <br></br>
                  </div>
                );
              })
            }
          </div>
        );
      })}
    </div >
  );
}

export default FinancesSummary;