import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService'

function Groceries() {
  //const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/groceries/GetGroceries1', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }

    /*authService.getAccessToken()
      .then(token => {
        return fetch('api/groceries/GetGroceries1', {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })          
      })
      .then(response => response.json())
      .then(data => { setData(JSON.stringify(data)); })
      .catch(error => {
        console.error(error);
      });*/
  };

  const handleRedirect = () => {
    const authorizationEndpoint = "https://api.kroger.com/v1/connect/oauth2/authorize";
    const client_id = "mealplanningbyenough-7b56c8d1e724d9c0a756e9ed09c6a3454296828443381576737";
    const redirect_uri = "https://localhost/api/groceries/CartAccessCode"; //"https://localhost/groceries"; //"https://todoenough.org/groceries";
    const queryParams = `scope=cart.basic:write&response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}`;
    const apiUrl = `${authorizationEndpoint}?${queryParams}`;
    window.location.href = apiUrl;
  };

  const handleButtonClick = async () => {
    console.log("handleButtonClick");
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/groceries/additem', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });

      await getData();  //update list
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>GROCERIES!</h1>
      {/* Render the received data */}
      {data && <p>{data}</p>}
      <button onClick={handleButtonClick}>Call Controller Function</button><br>
      </br><br></br>
      <button onClick={handleRedirect}>Redirect</button>
    </div>
  );
}

export default Groceries;