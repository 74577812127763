import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, DropdownDivider } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import authService from './api-authorization/AuthorizeService';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isAuthenticated: false
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const isAuthenticated = await authService.isAuthenticated();
    this.setState(prevState => ({
      ...prevState,
      isAuthenticated: isAuthenticated
    }));
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">Enough</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
              </NavItem>*/}
              {this.state.isAuthenticated && <NavItem>
                <NavLink tag={Link} className="text-dark" to="/groceries">Groceries</NavLink>
              </NavItem>}
              {this.state.isAuthenticated &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Finances
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem tag={Link} to="/finances/summary">Summary</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/accounts">Accounts</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/sales">Sales</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/staking">Staking</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/tagged">Tagged Transactions</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/uploadinvestments">Upload Investments</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/uploadstatements">Upload Statements</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/match">Match Sales and Deposits</DropdownItem>
                      <DropdownItem tag={Link} to="/finances/settings">Settings</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>}
              {this.state.isAuthenticated &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Todo
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem tag={Link} to="/todo/dump">Dump</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>}
              <LoginMenu>
              </LoginMenu>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
