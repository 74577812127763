import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';

function FinancesByAccount() {
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    getPrices();
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getaccountssummary', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPrices = async () => {
    try {
      const token = await authService.getAccessToken();
      var response = await fetch('api/finances/getprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      var data = await response.json();
      setPrices(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePricesClick = async () => {
    console.log("handleUpdatePricesClick");
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/updateassetprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });

      await getPrices();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleOpen = (id) => {
    setOpenItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const topTotals = {
    currentTotal: 0,
  }

  const totalsResult = data
    .flatMap(accountGroup => accountGroup.assets.flatMap(asset => asset.transactions))
    .reduce((acc, transaction) => {
      if (prices[transaction.symbol])
      {
        topTotals.currentTotal += prices[transaction.symbol].price * transaction.currentQuantity;
      }
      else {
        console.error(`Price not found for symbol: ${transaction.symbol}`);
      }
      return acc;
    }, topTotals);

   const toCurrency = (str) =>
   {
     return str.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
   }

  const buttonStyle = {
    padding: "2px",
    fontSize: "20px",
    marginLeft: "10px",
    border: 'none',
    backgroundColor: 'white',
  };

  return (
    <div>
      <h2>Accounts</h2>
      Current: <b>{toCurrency(topTotals.currentTotal)}</b>
      <button style={buttonStyle} onClick={handleUpdatePricesClick}>(&#10227;)</button><br></br>
      <br></br>
      <div>
        {data.filter(accountGroup => true) //accountGroup.assets.length > 0)
          .sort((a, b) => a.account.friendlyName.localeCompare(b.account.friendlyName))
          .map(accountGroup => {
            const totalCostBasis = accountGroup.assets.reduce((acc, symbolData) => acc + symbolData.costBasis, 0);
            const totalCurrentTotal = accountGroup.assets.reduce((acc, symbolData) => {
              if (prices[symbolData.symbol]) {
                return acc + (prices[symbolData.symbol].price * symbolData.totalQuantity);
              }
              else {
                console.error(`Price not found for symbol: ${symbolData.symbol}`);
                return acc;
              }
            }, 0);
            const totalFutureTotal = accountGroup.assets.reduce((acc, symbolData) => {
              if (prices[symbolData.symbol]) {
                return acc + (prices[symbolData.symbol].futurePrice * symbolData.totalQuantity);
              }
              else {
                console.error(`Price not found for symbol: ${symbolData.symbol}`);
                return acc;
              }
            }, 0);

            return (
              <div key={accountGroup.account.id}>
                <h4 title={accountGroup.account.id}>{accountGroup.account.friendlyName}</h4>
                {(accountGroup.needsMatch > 0) && <p><span style={{ backgroundColor: 'yellow' }}>Unmatched transactions: {accountGroup.needsMatch}</span></p>}
                {(accountGroup.depositNeedsBasis > 0) && <p><span style={{ backgroundColor: 'yellow' }}>Deposits need basis: {accountGroup.depositNeedsBasis}</span></p>}
                {(accountGroup.awardNeedsBasis > 0) && <p><span style={{ backgroundColor: 'yellow' }}>Award needs basis: {accountGroup.awardNeedsBasis}</span></p>}
                <table className="table">  {/*table-striped*/}
                  <thead className="thead-dark">
                    <tr>
                      <th>Symbol</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Current Price</th>
                      <th>Future Price</th>
                      <th>Cost Basis</th>
                      <th>Current Total</th>
                      <th>Gains</th>
                      <th>Future Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountGroup.assets.map((symbolData, index) => (
                      <>
                        <tr key={symbolData.symbol} style={{ borderBottom: '0px solid #fff' }}>  {/* className={index % 2 === 0 ? "table-primary" : ""}*/}
                          <td>
                            <button onClick={() => toggleOpen(accountGroup.account.id * 100 + index)} className="btn" style={{ marginBottom: '0rem', padding: '0px', margin: '0px', verticalAlign: 'center' }}>                                
                            {symbolData.symbol}
                              </button>
                            </td>
                            <td>{symbolData.totalQuantity.toFixed(8)}</td>
                            <td>{Math.abs(symbolData.costBasis / symbolData.totalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{prices[symbolData.symbol]?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{prices[symbolData.symbol]?.futurePrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{Math.abs(symbolData.costBasis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{(prices[symbolData.symbol]?.price * symbolData.totalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{(symbolData.costBasis + (prices[symbolData.symbol]?.price * symbolData.totalQuantity)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            <td>{(prices[symbolData.symbol]?.futurePrice * symbolData.totalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        </tr>
                        <tr>
                          <td colSpan="9">
                            <Collapse isOpen={openItems[accountGroup.account.id * 100 + index]}>
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Current Price</th>
                                    <th>Cost Basis</th>
                                    <th>USDChange</th>
                                    <th>Current Total</th>
                                    <th>Gain</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {symbolData.transactions.map((transaction, index) => (
                                    <tr key={transaction.symbol}>
                                      <td>{transaction.date}</td>
                                      <td>{transaction.type}</td>
                                      <td>{transaction.currentQuantity.toFixed(8)}</td>
                                      <td>{Math.abs(transaction.currentBasis / transaction.currentQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td >
                                      <td>{prices[symbolData.symbol]?.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                      <td>{Math.abs(transaction.currentBasis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                      <td>{transaction.usdChange.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                      <td>{(prices[symbolData.symbol]?.price * transaction.currentQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                      <td>{(transaction.currentBasis + prices[symbolData.symbol]?.price * transaction.currentQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    </tr>
                                   ))}
                                </tbody>
                               </table>
                            </Collapse>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr style={{ borderBottom: '0px solid #fff' }}>
                      <td><strong>Cash</strong></td>
                      <td>{accountGroup.cash.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{Math.abs(totalCostBasis).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td><b>{totalCurrentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b></td>
                      <td>{(totalCostBasis + totalCurrentTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                      <td>{totalFutureTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    </tr>
                  </tfoot>
                </table>
                <br></br>
                <br></br>
              </div>
            );
        })}
      </div>
    </div>
  );
}

export default FinancesByAccount;