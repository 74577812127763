import { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService'

const DumpList = () => {
  const [dumpList, setDumpList] = useState([]);
  const [filteredDumpList, setFilteredDumpList] = useState([]);
  const [tagFilter, setTagFilter] = useState('');

  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchDumpList = async () => {
    const token = await authService.getAccessToken();
    const response = await fetch('api/dump/getdumpitems', {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    setDumpList(data);
    setFilteredDumpList(data);
  };

  /*const addDumpItem = (newItem) => {
    setDumpList(prevList => [...prevList, newItem]);
  };*/

  useEffect(() => {
    fetchDumpList();
  }, []);

  useEffect(() => {
    if (tagFilter === '') {
      setFilteredDumpList(dumpList);
    } else {
      setFilteredDumpList(dumpList.filter(item => item.itemTags.some(tag => tag.name === tagFilter)));
    }
  }, [tagFilter, dumpList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Sanitize input before sending to backend
    const sanitizedText = text.trim(); // Add additional sanitization if necessary

    if (!sanitizedText) {
      setError('Input cannot be empty');
      return;
    }

    // Make the POST request to add the new DumpItem
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/dump/adddumpitem', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitizedText),
      });

      if (response.ok) {
        setSuccess('DumpItem added successfully!');
        fetchDumpList();
        //addDumpItem(sanitizedText);
        setText('');
      } else {
        setError('Failed to add DumpItem');
      }
    } catch (error) {
      setError('Error connecting to the server');
    }
  };

  return (
    <div>
      <h2>Add New DumpItem</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter new DumpItem"
          />
        </div>
        <button type="submit">Add</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <input
        type="text"
        placeholder="Filter by tag"
        value={tagFilter}
        onChange={(e) => setTagFilter(e.target.value)}
      />
      <ul>
        {filteredDumpList.map(item => (
          <li key={item.id}>
            <strong>{item.text }</strong> {item.tags ?.map(tag => tag.name).join(', ')}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DumpList;