import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { Collapse } from 'reactstrap';

export const NoColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  return (
   <></>
  );
};

export const EditBoxColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};


export const SelectOptionColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const MultiSelectFilter = ({ column: { filterValue=[], setFilter, preFilteredRows, id } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const handleChange = (value) => {
    if (filterValue?.includes(value)) {
      setFilter(filterValue.filter((val) => val !== value));
    } else {
      setFilter([...filterValue, value]);
    }
  };

  return (
    <>
      <button
        onClick={toggle}
        title="Toggle Show Filters">
        {isOpen ? '-' : '+'}
      </button>
      <Collapse isOpen={isOpen}>
        <div>
          {options.map(option => (
            <div key={option}>
              <label>
                <input
                  type="checkbox"
                  value={option}
                  checked={filterValue.includes(option)}
                  onChange={() => handleChange(option)}
                />
                {option}
              </label>
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};

export const includesOr = (rows, id, filterValue) => {
  if (filterValue.length === 0) return rows;
  return rows.filter(row => filterValue.includes(row.values[id]));
};

export const CurrencyCell = ({ value }) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const Table = ({ columns, data, nestedColumns, getNestedData }) => {

  const defaultColumn = React.useMemo(
    () => ({
      Filter: NoColumnFilter, // Fallback filter
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      includesOr,
    }),
    []
   );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy
  );

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowIndex) => {
    setExpandedRows({
      ...expandedRows,
      [rowIndex]: !expandedRows[rowIndex],
    });
  };

  return (
    <table {...getTableProps()} className="table">
      <thead className="thead-dark">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
                {column.canFilter ? column.render('Filter') : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <React.Fragment key={rowIndex}>
              <tr {...row.getRowProps()} onClick={() => toggleRow(rowIndex)} style={{ cursor: 'pointer', border: '1px solid black' }}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '10px' }}>{cell.render('Cell')}</td>
                ))}
              </tr>
              {nestedColumns && (
                <tr>
                  <td colSpan={columns.length} style={{ padding: 0, border: 'none' }}>
                    <Collapse isOpen={expandedRows[rowIndex]}>
                      <div style={{ padding: '10px', border: '1px solid black', background: '#f9f9f9' }}>
                        <Table columns={nestedColumns} data={getNestedData(row)}></Table>
                      </div>
                    </Collapse>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()} className="table-footer-row">
            {group.headers.map(column => (
              <td {...column.getFooterProps()} className="table-footer-cell">
                {column.render('Footer')}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};

export default Table;