import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';

function FinancesMatch() {
  const [data, setData] = useState([]);
  const [quantitySelected, setQuantitySelected] = useState(0);
  const [accountFilter, setAccountFilter] = useState("0");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      clearCheckBoxes();
      setQuantitySelected(0);
      disableAllButtons();
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getunmatched', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
      enableAllButtons();
    } catch (error) {
      console.error(error);
    }
  };

  const disableAllButtons = () => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => { button.disabled = true; });
  };

  const enableAllButtons = () => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => { button.disabled = false; });
  };

  const clearCheckBoxes = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => { checkbox.checked = false; });
  };

  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  function handleCheckboxQuantityChange(event, quantity) {
    const isChecked = event.target.checked;
    if (isChecked)
      setQuantitySelected(quantitySelected + quantity);
    else
      setQuantitySelected(quantitySelected - quantity);
  }

  const handleActionOnTransactions = async (action) =>
  {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');

    const transactionIds = [];
    checkboxes.forEach((checkbox) => { transactionIds.push(checkbox.id); });

    const token = await authService.getAccessToken();

    fetch(action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
      body: JSON.stringify(transactionIds),
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            return response.json().then(errorData => {
              throw new Error(JSON.stringify(errorData.errors));
            });
          } else {
            throw new Error('Failed: ' + response.statusText);
          }
        }
        getData();
      })
      .catch(error => {
        console.error('Error with matchtransactions:', error);
        //setMessage('Error uploading file: ' + error.message);
      });

    await getData();

  }

  const handleMatchTransactionsButtonClick = async () =>
  {
    await handleActionOnTransactions('api/finances/matchtransactions');
  }

  const handleIgnoreTransactionsButtonClick = async () => {
    await handleActionOnTransactions('api/finances/ignoretransactions');
  }

  const handleAccountChange = (event) => {
    const selectedAccountId = event.target.value;
    console.log('Selected account ID:', selectedAccountId);
    setAccountFilter(selectedAccountId);
  };

  return (
    <div>
      <div>
        <h2>Match</h2>
        <br></br>
        <div>
          <select onChange={handleAccountChange}>
            <option value="0">Select an account</option>
            {
              data?.accounts?.map(account => (
              <option key={account.id} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
        </div>
        <br></br>
        {data?.matchAll?.map((symbolGroup, index) => {
            const totalIncorrectDepositBasis = symbolGroup.assets.filter(t => t.type === "DEPOSIT" && t.needsMatch).length;
            const totalIncorrectWithdrawalBasis = symbolGroup.assets.filter(t => t.type === "WITHDRAWAL" && t.needsMatch).length;
            const totalUnmatchedSale = symbolGroup.assets.filter(t => t.type === "SELL").length;
            const totalUnmatched = totalUnmatchedSale + totalIncorrectDepositBasis + totalIncorrectWithdrawalBasis;
            return (
              <Accordion open={open} toggle={toggle} stayopen>
                <AccordionItem targetId={symbolGroup.symbol + index.toString()}>
                  <AccordionHeader targetId={symbolGroup.symbol + index.toString()}>
                    <h4>{symbolGroup.symbol} ({totalUnmatched}) - {totalIncorrectDepositBasis} Deposits, {totalIncorrectWithdrawalBasis} Withdrawals, {totalUnmatchedSale} Sales</h4>
                  </AccordionHeader>
                  <AccordionBody accordionId={symbolGroup.symbol + index.toString()}>
                    <h5>Quantity Selected: {quantitySelected}</h5>
                    <button onClick={handleIgnoreTransactionsButtonClick}>Ignore</button>
                    <table className="table" style={{ flex: 1 }}>
                      <thead className="thead-dark">
                        <tr>
                          <th>Id</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Type</th>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>PriceUSD</th>
                          <th>CryptoFee</th>
                          <th>Basis</th>
                          <th>Selected</th>
                          <th>Match</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {symbolGroup?.assets?.filter(t => (accountFilter === "0" ? true : t.accountId.toString() === accountFilter))
                          .map((transaction, index2) => {
                            const incorrectDepositBasis = transaction.type === "DEPOSIT" && transaction.needsMatch;
                            const incorrectWithdrawalBasis = transaction.type === "WITHDRAWAL" && transaction.needsMatch;
                            const unmatchedSale = transaction.type === "SELL";
                            const needsMatching = unmatchedSale || incorrectDepositBasis || incorrectWithdrawalBasis;
                            return (
                              <tr key={transaction.id} style={{
                                backgroundColor: incorrectDepositBasis ? 'lightsalmon' : (incorrectWithdrawalBasis ? 'lightgrey' : (unmatchedSale ? 'lightyellow' : 'inherit')),
                                fontWeight: needsMatching ? 'bold' : 'normal'
                              }}>
                                <td>{transaction.id}</td>
                                <td>
                                  {new Date(transaction.date).toLocaleDateString('en-US', { dateStyle: 'short' })}
                                  <br />
                                  {new Date(transaction.date).toLocaleTimeString('en-US', { timeStyle: 'short' })}
                                </td>
                                <td><div title={transaction.accountId.toString()}>{transaction.accountName}</div></td>
                                <td>{transaction.type + '-' + transaction.subtype}</td>
                                <td>{transaction.symbol}</td>
                                <td><input type="checkbox"
                                  id={transaction.id}
                                  onChange={(event) => handleCheckboxQuantityChange(event, transaction.currentQuantity)}
                                  style={{ marginRight: 10 }} />{transaction.currentQuantity}
                                  <br></br>
                                  {transaction.originalQuantity !== transaction.currentQuantity && "(" + transaction.originalQuantity + ")"}
                                </td>
                                <td>{
                                  transaction.currentQuantity !== 0 ?
                                    Math.abs(transaction.currentBasis / transaction.currentQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) :
                                    Math.abs(transaction.currentBasis / transaction.originalQuantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                                }</td>
                                <td>{transaction.cryptoFee}</td>
                                <td>{transaction.currentBasis}<br></br>{transaction.originalBasis !== transaction.currentBasis && "(" + transaction.originalBasis + ")"}</td>
                                <td>
                                  <div>
                                      <p>{quantitySelected}</p>
                                    </div>
                                </td>
                                <td>
                                  {needsMatching && <button onClick={handleMatchTransactionsButtonClick}>Match</button>}
                                </td>
                                <td>
                                  <input type="text" id="fname" name="firstname" placeholder=""></input>
                                </td>
                              </tr>
                            );
                        })}
                      </tbody>
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}

export default FinancesMatch;