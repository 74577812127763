import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import Table, { CurrencyCell, MultiSelectFilter, includesOr } from './controls/Table';

function FinancesSales() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getsales', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const oneYearInMilliseconds = 1000 * 60 * 60 * 24 * 365;
  const isLongTerm = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    if (isNaN(date1) || isNaN(date2)) {
      throw new Error("Invalid date format");
    }

    return Math.abs(date2 - date1) >= oneYearInMilliseconds;
  }
  
  const bySymbolColumns = React.useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Short Term Gains',
        accessor: 'shortTermGains',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.shortTermGains;
          const b = rowB.original.shortTermGains;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.shortTermGains + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Long Term Gains',
        accessor: 'longTermGains',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.longTermGains;
          const b = rowB.original.longTermGains;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.longTermGains + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Tax Free Gains',
        accessor: 'taxFreeGains',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.taxFreeGains;
          const b = rowB.original.taxFreeGains;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.taxFreeGains + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Total Gains',
        accessor: 'totalGains',
        Cell: ({ row }) => {
          const price = row.original.proceeds - row.original.costBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.proceeds - rowA.original.costBasis;
          const b = rowB.original.proceeds - rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.original.proceeds - row.original.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Gains %',
        accessor: 'gainsPercent',
        Cell: ({ row }) => {
          const percent = (row.original.proceeds - row.original.costBasis) / row.original.costBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'percent' }).format(percent)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = (rowA.original.proceeds - rowA.original.costBasis) / rowA.original.costBasis;
          const b = (rowB.original.proceeds - rowB.original.costBasis) / rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const gains = info.rows.reduce((sum, row) => (row.original.proceeds - row.original.costBasis) + sum, 0);
          const costBasis = info.rows.reduce((sum, row) => row.original.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'percent' }).format(gains / costBasis)}</>;
        },
      },
    ],
    []
  );
  
  const salesColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Footer: '',
      },
      {
        Header: 'Origin',
        accessor: 'originTransaction.id',
        Footer: '',
      },
      {
        Header: 'Sale',
        accessor: 'saleTransaction.id',
        Footer: '',
      },
      {
        Header: 'Account',
        Cell: ({ row }) => {
          const label = row.original.saleTransaction.account.friendlyName + " (" + row.original.saleTransaction.account.id + ")";
          return <>{label}</>;
        },
        Footer: '',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Footer: '',
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        Footer: '',
      },
      {
        Header: 'Date Acquired',
        accessor: 'datePurchased',
        Footer: '',
      },
      {
        Header: 'Price',
        accessor: 'price1',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.costBasis / row.original.shares);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = Math.abs(rowA.original.costBasis / rowA.original.shares);
          const b = Math.abs(rowB.original.costBasis / rowB.original.shares);
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'Date Sold',
        accessor: 'dateSold',
        Footer: '',
      },
      {
        Header: 'Price',
        accessor: 'price2',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.proceeds / row.original.shares);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = Math.abs(rowA.original.proceeds / rowA.original.shares);
          const b = Math.abs(rowB.original.proceeds / rowB.original.shares);
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'Cost Basis',
        accessor: 'costBasis',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.costBasis;
          const b = rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Proceeds',
        accessor: 'proceeds',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.proceeds;
          const b = rowB.original.proceeds;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.proceeds + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Gains',
        accessor: 'realizedGains',
        Cell: ({ row }) => {
          const gains = row.original.proceeds - row.original.costBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(gains)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.proceeds - rowA.original.costBasis;
          const b = rowB.original.proceeds - rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.proceeds - row.values.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: '',
        accessor: 'gainsPercent',
        Cell: ({ row }) => {
          const gains = (row.original.proceeds - row.original.costBasis) / row.original.costBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'percent' }).format(gains)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = (rowA.original.proceeds - rowA.original.costBasis) / rowA.original.costBasis;
          const b = (rowB.original.proceeds - rowB.original.costBasis) / rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const gains = info.rows.reduce((sum, row) => (row.values.proceeds - row.values.costBasis) + sum, 0);
          const costBasis = info.rows.reduce((sum, row) => row.values.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'percent' }).format(gains / costBasis)}</>;
        },
      },
      {
        Header: 'Tax',
        accessor: 'tax',
        Cell: ({ row }) => {
          const tax = row.original.saleTransaction.account.taxFree ? "-" : isLongTerm(row.original.datePurchased, row.original.dateSold) ? "Long" : "Short"
          return <>{tax}</>;
        },
        Footer: '',
      },
    ],
    []
  );

  const getNestedData = (row) => {
    return row.original.sales || [];
  };

  return (
    <div>
      <div>
        <h2>Sales</h2>
        <br></br>
        {data?.map((salesGroup, index) => {
          const gains = salesGroup.symbols.reduce((acc, symbol) => { return acc + (symbol.proceeds - symbol.costBasis) }, 0);
          const shortTermGains = salesGroup.symbols.reduce((acc, symbol) => { return acc + symbol.shortTermGains }, 0);
          const longTermGains = salesGroup.symbols.reduce((acc, symbol) => { return acc + symbol.longTermGains }, 0);
          const taxFreeGains = salesGroup.symbols.reduce((acc, symbol) => { return acc + symbol.taxFreeGains }, 0);
            return (
              <Accordion open={open} toggle={toggle} stayopen>
                <AccordionItem targetId={salesGroup.year}>
                  <AccordionHeader targetId={salesGroup.year}>
                    <h4>{salesGroup.year}: {gains.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h4>
                    {/*<h6> Taxes - 
                    Short: {shortTermGains.toLocaleString('en-US', { style: 'currency', currency: 'USD' })},
                    Long: {longTermGains.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}, 
                    None: {taxFreeGains.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h6>*/}
                  </AccordionHeader>
                  <AccordionBody accordionId={salesGroup.year}>
                    <Table
                      columns={bySymbolColumns}
                      data={salesGroup.symbols}
                      nestedColumns={salesColumns}
                      getNestedData={getNestedData}
                    >
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}

export default FinancesSales;