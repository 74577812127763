import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import authService from './api-authorization/AuthorizeService'

function FinancesSettings() {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getaccounts', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setAccounts(data);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleHideAccount = async (accountId) => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(`api/finances/togglehideaccount?accountId=${accountId}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setAccounts(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>Account</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account, index) => (
            <tr>
              <td>{account.name}</td>
              <td>
                <Button color="secondary" outline onClick={() => toggleHideAccount(account.id)}>{account.hide ? 'Show' : 'Hide'}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FinancesSettings;